import httpClient, { ClientId, ClientSecret } from "./HttpClient";
import { TokenResponse } from "@/types/IdentityServer/Response/TokenResponse";
import { UserInfoResponse } from "@/types/IdentityServer/Response/UserInfoResponse";
import { ValidationResponse } from "@/types/IdentityServer/Response/ValidationResponse";
import { ValidateMemberRequest } from "@/types/IdentityServer/Request/ValidateMemberRequest";

const getTokenAsync = (loginId: string, password: string, ipAddress: string): Promise<TokenResponse> => {
  const endPoint = `/connect/token`;
  Object.assign(httpClient.defaults, { headers: { "X-Origin-IP": ipAddress } });
  return httpClient
    .post(
      endPoint,
      new URLSearchParams({
        grant_type: "password",
        username: loginId,
        password: password,
        client_id: ClientId,
        client_secret: ClientSecret,
      })
    )
    .then((response) => {
      response.data.loginStatus = true;
      return response.data;
    })
    .catch((err) => {
      const errorResponse = { loginStatus: false, isPhoneConfirmError: false, phoneNumber: loginId };
      if (err.response.status == 400) {
        if (err.response.data.error_code == 403) {
          if (err.response.data.user.status == "ACTIVE" && !err.response.data.user.phoneNumberConfirmed) {
            errorResponse.isPhoneConfirmError = true;
          }
        }
      }
      return errorResponse;
    });
};

const getRegisterTokenAsync = (): Promise<TokenResponse> => {
  const endPoint = `/connect/token`;
  return httpClient
    .post(
      endPoint,
      new URLSearchParams({
        grant_type: "client_credentials",
        client_id: ClientId,
        client_secret: ClientSecret,
      })
    )
    .then((response) => response.data);
};

const getLoginUserInfo = (): Promise<UserInfoResponse> => {
  const endPoint = `/connect/userinfo`;
  return httpClient.get(endPoint).then((response) => response.data);
};

const resumeSession = (memberId: string): Promise<ValidationResponse> => {
  const endPoint = `/api/Session`;
  Object.assign(httpClient.defaults, { headers: { "Content-Type": "application/json" } });
  const request: ValidateMemberRequest = {
    memberId: memberId,
  };
  return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const createSession = (memberId: string): Promise<ValidationResponse> => {
  const endPoint = `/api/Session`;
  Object.assign(httpClient.defaults, { headers: { "Content-Type": "application/json" } });
  const request: ValidateMemberRequest = {
    memberId: memberId,
  };
  return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const deleteSession = (memberId: string): Promise<any> => {
  const endPoint = `/api/Session/${memberId}`;
  return httpClient.delete(endPoint).then((response) => response.data);
};

export { getTokenAsync, getRegisterTokenAsync, getLoginUserInfo, resumeSession, createSession, deleteSession };
