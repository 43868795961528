export default {
  DOWNLOADNOW: "DOWNLOAD NOW",
  Description1: "Experience the very best online casino games",
  Description2: "Scan and play now",
  Title: "Games Available on MEGA888",
  Description3: "You can enjoy the high-quality board and slot games at MEGA888. In addition, we are working in the field with the main supplier- Real Time Gaming(RTG). This is a well-known brand in the casino industry worldwide. So, rest assured that our games fulfill all the requirements you need. If you think you might be walking into an unjust gambling system, don't worry. After all, MEGA888 only cooperates with game developers who have legitimate content at 100 percent. Please don't believe the casino is against you or trying to prevent you from winning. Our goal is to satisfy our customers.",
  Description4: "The number of online casino games available to play on MEGA888 is extensive, as long as you play at Mega 888 every month, it is rare for any player to run out of options. MEGA888 Malaysia 's entertainment content comes in a wide range, ranging from online fishing games to online slot games to a range of live table games like IDN Poker, baccarat, and roulette. Whatever online casino games you 're searching for, it does have MEGA888.",
  Description5: "The new releases in 2020 includes:",
  Game1: "Wolf Hunter",
  Game1Description: "Wolf Hunter is a beautiful slot game with graphics of triple-A grade. The game features a Victorian-era gothic style and tells the story of a hunter heading out to hunt for wolves at night. This is not just some ordinary wolves but werewolves. The game has elements of horror that keep the play fun and thrilling.",
  Game2: "Motorcycle",
  Game2Description: "Everyone is for you, for all you hot-headed speed addicts out there. The motorcycle is an adrenaline-pumping experience of a slot game that virtualizes Car Racing's insanity, one of the most viral and top trending slot games in this area.",
  Game3: "Dragon Maiden",
  Game3Description: "Enter the Dragon Maidens fantasy land where you can choose to tame or kill dragons. This is a slot game that puts its priority in its lore and story-telling elements, making it one of the more engaging and immersive experiences a slot game can offer.",
  Game4: "Steam Tower",
  Game4Description: "Steam Tower is a slot game based on steampunk aesthetics that tells the story of a lonesome detective trying to ascend the steam tower in distress to rescue the damsel. He must face all manner of tenacity and danger on his way to the top, ranging from monsters, dragons to mobs. His preferred weapon is a badass harpoon rifle. If you like games like steampunk like Bioshock and System Shock, then that game is definitely for you.",
  DownloadTitle : "How to Download MEGA888",
  iosDownload : "iOS Download",
  AndroidDownload : "Android Download",
  iosDownloadDesription: "As an iOS user, pay careful attention to the details, installing a third-party app on an Apple device is a bit complicated, but if these steps are followed it can be done easily.",
  AndroidDownloadDesription: "The Android download is simpler and more straightforward; however, we'll explain it to those that aren't used to update third-party applications:",
  iosDownloadSteps1: "1. Open your web browser and enter 'http://www.mymega888.vip/Download'. This will take you straight to this site, a trustworthy site from which you can download the apk file.",
  iosDownloadSteps2: "2. For your Apple iPhone, choose the iOS edition of the app.",
  iosDownloadSteps3: "3. Wait to finish downloading. When a pop-up menu shows install.",
  iosDownloadSteps4: "4. When the notification 'Download Successful' appears on your computer, press the apk file icon 'MEGA888' and wait for a pop-up that reads 'Untrusted Enterprise Developer' this error message should pop up as the website is not native. iOS does not allow Untrusted Source downloads.",
  iosDownloadSteps5: "5. Next click 'Cancel,' button on your iOS computer to leave your browser tab, and then open the 'Settings' menu. Select 'System Management' option under 'General' and now check for 'All Continental Trading Sdn. Trust All Continental Trading Sdn.' and press' highlight. Bhd.' and 'Trust' confirmation.",
  iosDownloadSteps6: "6. The reason for all of this is that iOS device have a built-in firewall that prevents untrusted websites from accessing and using files and apps. The MEGA888 program will circumvent the firewall this way, with a name change. After installing the program, you can enjoy the app for free.",
  AndroidDownloadSteps1: "1. Open your browser and enter 'http://www.mymega888.vip/Download'.",
  AndroidDownloadSteps2: "2. Pick the Android option. You will be prompted to allow the download of an apk file, approve it and finally wait for it to download.",
  AndroidDownloadSteps3: "3. After downloading the apk, select it to install it from the download selection.",
  AndroidDownloadSteps4: "4. You need to allow this option in the Settings to install this third-party device.",
  AndroidDownloadSteps5: "5. Tap the 'Settings' button on your phone and scroll to the 'Privacy' section in later android models, it's also 'hidden sources.' Whichever way it appears is the choice you want to edit.",
  AndroidDownloadSteps6: "6. Click 'allow' this option to install from sources of apps that aren't Google Play store.",
  AndroidDownloadSteps7: "7. Click 'Install.' Since you've changed your phone's settings to install apps from unknown sources, this MEGA888 apk file will install without any hitches on your phone.",
  AndroidDownloadSteps8: "8. Now that you have the app installed open it and begin the registration progress.",
};
