import { ActionTree } from "vuex";
import {
  getTokenAsync,
  getRegisterTokenAsync,
  getLoginUserInfo,
  resumeSession,
} from "@/network/IdentityServerService/IdentityServer";
import { IdentityServerStateInterface } from "./state";
import { RootState } from "@/store/state";
import { IdentityServerMutations } from "./mutations";
import CryptoJS from "crypto-js";

// let timer: ReturnType<typeof setTimeout>;

export const actions: ActionTree<IdentityServerStateInterface, RootState> = {
  async fetchLoginToken(context, payload) {
    const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
    const res = await getTokenAsync(payload.phoneNo, payload.password, ipAddress);
    if (res.loginStatus) {
      const expirationDate = new Date().getTime() + res.expires_in * 1000;
      //Token Encryption
      const cipher = CryptoJS.AES.encrypt(
        res.access_token,
        CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key),
        {
          iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
          mode: CryptoJS.mode.CBC,
        }
      );

      //Insert value into local storage
      localStorage.setItem("token", cipher.toString());

      //Fetch User Info
      await context
        .dispatch("fetchUserInfo", res.access_token)
        .then(() => {
          localStorage.setItem("tokenExpiration", expirationDate.toString());
        })
        .catch(() => {
          localStorage.removeItem("token");
          throw new Error();
        });
      await context
        .dispatch(
          "member/fetchAspUserClient",
          { clientId: process.env.VUE_APP_IdentityServer_ClientId },
          { root: true }
        )
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          throw new Error("GameError");
        });
      context.commit(IdentityServerMutations.SET_LOGIN_STATUS, { loginStatus: true });
      context.commit(IdentityServerMutations.SET_ONSUBMIT_LOGIN, { submitLoginStatus: true });
      context.commit(IdentityServerMutations.SET_AUTO_LOGOUT, { autoLogoutStatus: false });
      return { loginStatus: true };
    } else {
      return res;
    }
  },

  async fetchRegisterToken() {
    return await getRegisterTokenAsync().then((response) => response.access_token);
  },

  async fetchUserInfo(context) {
    const res = await getLoginUserInfo();
    context.commit(IdentityServerMutations.SET_MEMBER_INFO, res);
    return res;
  },

  async validation(_, payload) {
    const res = await resumeSession(payload.sub);
    return res;
  },

  async tryLogin(context) {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (token) {
      const isInterchange = context.rootGetters["identityServer/getInterchangeStatus"];
      context.commit(IdentityServerMutations.SET_AUTO_LOGOUT, { autoLogoutStatus: false });
      if (!isInterchange) {
        await context
          .dispatch("fetchUserInfo")
          .then(() => {
            if (tokenExpiration != null) {
              const expiresIn = +tokenExpiration - new Date().getTime();
              if (expiresIn > 0) {
                if (expiresIn < 3600 * 1000) {
                  context.dispatch("logOut");
                  return;
                }
              }
              setTimeout(function () {
                context.dispatch("autoLogout");
              }, expiresIn);
            }
            context.commit(IdentityServerMutations.SET_LOGIN_STATUS, { loginStatus: true });
            context.dispatch("setInterchangeStatus", false);
          })
          .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
          });
        context.commit(IdentityServerMutations.SET_ONREFRESH_STATUS, { refreshStatus: false });
      }
    } else {
      context.commit(IdentityServerMutations.SET_ONREFRESH_STATUS, { refreshStatus: false });
      return;
    }
  },

  logOut(context) {
    localStorage.removeItem("token");
    context.commit(IdentityServerMutations.SET_MEMBER_INFO, { name: "", sub: "" });
    context.commit(IdentityServerMutations.SET_LOGIN_STATUS, { loginStatus: false });
  },

  autoLogout(context) {
    context.commit(IdentityServerMutations.SET_AUTO_LOGOUT, { autoLogoutStatus: true });
    context.dispatch("logOut");
  },
  async setInterchangeStatus(context, payload) {
    context.commit(IdentityServerMutations.SET_INTERCHANGE, payload);
  },
  async tokenTransfer(context, payload) {
    const cipher = CryptoJS.AES.encrypt(payload.token, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key), {
      iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
      mode: CryptoJS.mode.CBC,
    });

    //Insert value into local storage
    localStorage.setItem("token", cipher.toString());
  },
};
