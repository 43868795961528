<template>
  <b-container class="mt-5 mb-5">
    <b-row>
      <b-col lg="6" md="12" id="WITHDRAWAL">
        <div class="reportTitle">
          {{ $t("HomeReport.WITHDRAWAL") }}
        </div>
        <div>
          {{ $t("HomeReport.UPDATE") }}
        </div>
        <b-col cols="12">
          <table class="table realTimeTable">
            <tbody>
              <tr v-for="topWithdrawalItem in topWithdrawalList" :key="topWithdrawalItem">
                <td style="width: 30px">
                  <div class="rankImg">&nbsp;</div>
                </td>
                <td>
                  <div class="realtimeUserId">{{ topWithdrawalItem.username }}</div>
                  <div class="realtimeDate">{{ topWithdrawalItem.datetime }}</div>
                </td>
                <td>
                  <div class="realtimeRM">{{ topWithdrawalItem.rm }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-col>
      <b-col lg="6" md="12" class="reportContainer" id="WINNER">
        <div class="reportTitle">
          {{ $t("HomeReport.WINNER") }}
        </div>
        <div>
          {{ $t("HomeReport.UPDATE") }}
        </div>
        <b-col cols="12">
          <table class="table realTimeTable">
            <tbody>
              <tr v-for="topWinnerItem in topWinnerList" :key="topWinnerItem">
                <td style="width: 30px">
                  <div class="rankImg">&nbsp;</div>
                </td>
                <td>
                  <div class="realtimeUserId">{{ topWinnerItem.username }}</div>
                  <div class="realtimeDate">{{ topWinnerItem.datetime }}</div>
                </td>
                <td>
                  <div class="realtimeRM">{{ topWinnerItem.rm }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      topWithdrawalList: [],
      topWinnerList: [],
      realTimeLabel: ["WITHDRAWAL", "WINNER"],
    };
  },
  methods: {
    async getWithdrawalList() {
      await this.$store.dispatch("topdata/fetchTopData", { type: 0 }).then(() => {
        this.topWithdrawalList = this.$store.getters["topdata/getTopData"];
      });
    },
    async getWinnerList() {
      await this.$store.dispatch("topdata/fetchTopData", { type: 1 }).then(() => {
        this.topWinnerList = this.$store.getters["topdata/getTopData"];
      });
    },
  },
  created() {
    this.getWithdrawalList();
    this.getWinnerList();
  },
};
</script>
<style scoped>
.reportTitle {
  color: #00cefc;
  font-size: 24px;
}
#WINNER {
  background: #002c8b !important;
}
.realTimeTable > tbody {
  color: white;
  background: transparent;
}
.realTimeTable > thead {
  color: #f8eb4c;
  background: transparent;
}
.realTimeTable > tbody > tr > td {
  vertical-align: middle;
  border: none;
  width: min-content;
}
.rankImg {
  background-image: url("@/assets/TopStar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}
.reportContainer {
  background: #001942;
  border-top: 5px solid #00cefc;
  border-radius: 20px;
  padding: 20px;
}
.realtimeUserId,
.realtimeDate {
  text-align: left;
}
.realtimeRM {
  text-align: right;
  color: #36ff70;
}
.realtimeDate {
  color: #7285a7;
}
.realTimeTable > tbody tr:nth-child(1) > td:nth-child(1) > .rankImg {
  background-image: url("@/assets/Top1.png");
}

.realTimeTable > tbody tr:nth-child(2) > td:nth-child(1) > .rankImg {
  background-image: url("@/assets/Top2.png");
}

.realTimeTable > tbody tr:nth-child(3) > td:nth-child(1) > .rankImg {
  background-image: url("@/assets/Top3.png");
}
</style>
