<template>
	<b-container class="mt-5 mb-5" style="background-color: #001f59">
		<b-row>
			<b-col cols="12" class="homeTitle">{{ $t("HomePromotion.PROMOTIONS") }}</b-col>
		</b-row>
		<Carousel
			v-if="promoList.length !== 0"
			id="promoCarousel"
			class="bannerMax"
			:autoplay="4000"
			:wrap-around="true"
			pause-autoplay-on-hover
			:settings="settings"
			:breakpoints="breakpoints"
		>
			<slide v-for="promoItem in promoList" :key="promoItem">
				<div style="padding: 10px; background: transparent">
					<BaseImage
						:imgSrc="imgUrl(promoItem)"
						mode="promoImg"
						:isFullUrl="false"
						fluid-grow
						@click="openPromoPage"
					></BaseImage>
				</div>
			</slide>

			<template #addons>
				<Navigation />
			</template>
		</Carousel>
	</b-container>
</template>

<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { getDefaultCultureCode, getCultureID } from "@/currentCulture";
export default {
	components: {
		Carousel,
		Slide,
		Navigation,
		BaseImage,
	},
	data() {
		return {
			imagePath: "Bonus/BannerImage/",
			promoList: [],
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				767: {
					itemsToShow: 3,
					snapAlign: "center",
				},
			},
		};
	},
	methods: {
		imgUrl(promoItem) {
			const cultureCode = getDefaultCultureCode();
			const currentLocaleID = getCultureID(cultureCode);
			const thisDescriptionItem = promoItem.description.filter((x) => x.languageId == currentLocaleID);
			return this.imagePath + thisDescriptionItem[0].image;
		},
		openPromoPage() {
			this.$router.push("/promotion");
		},
	},
	computed: {},
	watch: {},
	async mounted() {
		await this.$store.dispatch("bonus/fetchBonusList").then(() => {
			let promoList = this.$store.getters["bonus/getBonusList"].filter((bonus) => bonus.type == "Pre Bonus");
			this.promoList = promoList.slice(0, 4);
			// this.promoList = promoList.map((promoItem) => {
			//   promoItem.imgUrl = this.imagePath + promoItem.imgUrl;
			//   return promoItem;
			// });
		});
	},
};
</script>
<style scoped>
.promoImg {
	width: 100%;
	height: auto;
	max-height: 221px;
	max-width: 100%;
	border-bottom: 5px solid #00cefc;
	border-radius: 0px;
}
.promoItem {
	margin: 1rem 0.3rem;
	background: linear-gradient(180deg, #f03f5a 0%, #e995a2 100%);
	padding: 8px;
	cursor: pointer;
}
.promoDesc {
	border-right: 1px solid #002e8c;
	background-color: #001942;
	padding: 10px;
}
</style>
