export default {
  PRIVACYPOLICY: "DASAR PRIVASI",
  Description:
    "Sila baca dasar di bawah dengan teliti sebelum menggunakan Laman Web. Dengan menggunakan atau mendaftar dengan Laman Web, anda dianggap bersetuju dan menerima syarat-syarat dan Syarat Dasar Privasi ini. Jika anda tidak bersetuju untuk menerima dan terikat dengan Dasar Privasi ini atau sebarang pindaan kepadanya, sila tolak hentikan penggunaan Laman Web.",
  AmendmentorChangestothePolicy: "Pindaan atau Perubahan pada Polisi",
  Description1:
    "Kami berhak, pada bila-bila masa, untuk meminda Dasar Privasi ini. Sebarang perubahan sedemikian akan terikat dan berkesan sebaik sahaja menyiarkan perubahan di Laman Web. Tarikh di mana Dasar Privasi semasa berkuatkuasa akan ditunjukkan pada bahagian atas halaman ini untuk rujukan.",
  DataCollectionGeneralInformation: "Koleksi Data, Maklumat Am",
  Description2:
    "Apabila mengakses internet, pelayar atau perisian pelanggan boleh menghantar maklumat kepada pelayan yang menjadi tuan rumah di laman web yang anda lawati. Maklumat ini akan menghasilkan statistik mengenai pelawat laman web yang akan digunakan untuk tujuan pemasaran dan membantu kami meningkatkan pengalaman pengguna.",
  PersonallyIdentifiableInformation: "Maklumat Pengenalan Peribadi",
  Description3: "Kami mengumpul maklumat peribadi anda yang boleh membantu kami mengenal pasti anda. Maklumat ini mungkin termasuk, tetapi tidak terhad kepada:",
  DescP1: "Maklumat yang anda berikan semasa membuka akaun dengan kami seperti nama anda, alamat e-mel, nombor telefon, tarikh lahir dan sebarang butiran mandatori yang diperlukan oleh anda yang akan membolehkan kami menyediakan dan menguruskan akaun anda.",
  DescP2: "Sekiranya anda menghubungi kami, kami boleh menyimpan rekod surat-menyurat anda.",
  DescP3: "Sebarang data yang mungkin anda berikan hasil daripada promosi atau persaingan pemasaran.",
  DescP4: "Butiran aktiviti permainan atau pertaruhan anda di Laman Web.",
  DescP5:
    "Interaksi anda dengan ejen perkhidmatan pelanggan kami. Ini boleh direkodkan atau diselamatkan untuk membantu kami dalam latihan, pengurusan kualiti dan untuk membolehkan penyelesaian masalah dan pertanyaan cepat.Selain itu, butiran ini akan membolehkan kami menghubungi anda untuk tujuan yang berbeza dan memberikan perkhidmatan terbaik yang kami tawarkan. Maklumat peribadi ini juga akan digunakan untuk tujuan pemasaran, untuk memberitahu anda setiap kali kami mempunyai tawaran istimewa, promosi dan acara. Anda mengakui bahawa maklumat yang diberikan oleh anda mungkin berjumlah kepada data peribadi atau sensitif di bawah Akta Perlindungan Data tahun 1998, dan dengan menggunakan Laman Web, anda memberi persetujuan anda terhadap data anda yang diproses sebagaimana diperuntukkan di bawah dasar ini.",
  DisclosureonUseofYourInformation: "Pendedahan mengenai Penggunaan Maklumat Anda",
  Description4: "Dengan mendaftarkan akaun dengan kami, anda menerima bahawa kami boleh menyimpan maklumat pengguna anda dan bahawa ia mungkin dipegang oleh kami atau diberikan kepada syarikat pihak ketiga yang memprosesnya bagi pihak kami.",
  Description5: "Maklumat anda boleh diproses seperti, tetapi tidak terhad kepada contoh berikut:",
  DescD1: "Untuk pengurusan akaun dan memproses transaksi anda di Laman Web kami.",
  DescD2: "Peruntukan kepada anda tentang perkhidmatan perjudian dan pertaruhan.",
  DescD3: "Untuk tujuan pengesahan dan pengenalan, seperti untuk memastikan bahawa anda berumur dan tidak tinggal di mana-mana kawasan yang terhad seperti yang dinyatakan dalam Syarat Penggunaan Laman Web.",
  DescD4: "Untuk menyesuaikan bahan pemasaran mengikut keutamaan anda.",
  DescD5: "Untuk diagnostik dan penyelenggaraan laman web.",
  DescD6: "Untuk memantau dan memperbaiki perkhidmatan Laman Web.",
  DescD7: "Untuk pengurusan risiko, pengesanan penipuan dan pematuhan pengubahan wang haram.",
  DescD8: "Untuk mematuhi sebarang keperluan undang-undang atau peraturan.",
  DescD9: "Atas permintaan oleh pembekal perisian kami (yang juga terikat dengan syarat-syarat dasar ini) dalam menyediakan bantuan dan layanan yang berkaitan.",
  Description6:
    "Kami juga mempunyai hak untuk melakukan analisis statistik terhadap tingkah laku dan ciri pengguna untuk mengukur minat dan penggunaan, pelbagai bidang tapak dan, sebagai tambahan, untuk memaklumkan pengiklan tentang maklumat tersebut serta bilangan pengguna yang telah terdedah kepada atau yang telah mengklik pada banner pengiklanan mereka. Kami hanya akan menyediakan data agregat dari analisis ini kepada pihak ketiga.",
  Description7:
    "Mega888 adalah ahli pelbagai persatuan yang bertujuan untuk melindungi integriti sukan dan pertaruhan untuk mengelakkan manipulasi pertandingan. Oleh itu, pihak-pihak (Mega888 dan persatuan masing-masing) berhak untuk mengakses dan berkongsi data anda untuk tujuan memantau aktiviti pertaruhan.",
  Description8: "Anda mungkin ingin menandakan kotak yang berkenaan pada borang pendaftaran jika anda ingin menerima maklumat mengenai tawaran promosi. Jika pada bila-bila masa anda ingin menukar pilihan raya ini, sila hubungi wakil sokongan pelanggan kami.",
  Winnings: "Kemenangan",
  Description9:
    "Untuk tujuan pemasaran dan promosi, kami berhak untuk menerbitkan di Laman Web dan butiran bahan promosi lain untuk sebarang kemenangan dan / atau hadiah yang anda terima. Kami boleh menerbitkan nama pengguna anda atau nama disingkat, jumlah kemenangan dan apa-apa maklumat lain untuk mempromosikan Laman Web, jika perlu.",
  Security: "Keselamatan",
  Description10:
    "Kami mengekalkan cara yang tersedia untuk memastikan maklumat anda tetap selamat dengan kami. Semua maklumat dipindahkan menggunakan teknologi penyulitan dan sekali disimpan di pelayan kami, ia dikekalkan dengan selamat menggunakan teknologi terkini yang tersedia pada hari ini. Kedua-dua laman web dan perisian kami menggunakan semua cara yang ada untuk mengekalkan ketepatan dan privasi data dan untuk melindungi data anda daripada disalahgunakan dan / atau hilang. Oleh kerana keselamatan semua komunikasi melalui internet tidak sepenuhnya selamat, kami tidak dapat menjamin keselamatan apa-apa maklumat yang akan anda berikan kepada kami dalam penyediaan perkhidmatan kami. Dengan menggunakan Laman Web, anda dengan ini mengakui dan menerima implikasi keselamatan yang melekat menggunakan internet dan kami tidak menerima liabiliti untuk anda untuk sebarang kerugian atau kerosakan langsung, berbangkit, sampingan atau hukuman yang timbul akibat kejadian tersebut.",
  Description11: "Sila ambil perhatian bahawa kami akan menyimpan maklumat anda selagi diperlukan dengan wajar untuk tujuan yang mana ia diperolehi. Kami mungkin dikehendaki dalam keadaan tertentu untuk mengekalkan maklumat anda untuk tempoh yang tidak ditentukan.",
  Cookies: "Cookies",
  Description12:
    'Pemain harus memperhatikan bahawa maklumat peribadi dan data boleh dikumpulkan dan disimpan secara automatik semasa proses pendaftaran melalui penggunaan Cookies. "Cookie" adalah sekeping maklumat kecil yang dihantar oleh pelayan web ke pelayar web, yang membolehkan pelayan untuk mengumpulkan maklumat dari penyemak imbas. Kami menggunakan cookies untuk menjejaki corak penyemakan imbas anda dan untuk membina profil demografi. Dengan memantau pola tersebut dan menyusun data yang ditangkap, kami dapat meningkatkan perkhidmatan kami kepada anda. Sistem Gabungan menggunakan cookies sebagai sebahagian daripada proses pengesanan kembali kepada afiliasi merujuk.',
  Description13:
    'Terdapat prosedur mudah di kebanyakan penyemak imbas yang membolehkan anda menolak atau menerima ciri Cookie jika anda tidak mahu maklumat yang dikumpulkan melalui penggunaan Cookies. Walau bagaimanapun, perhatikan bahawa perkhidmatan "diperibadikan" mungkin terjejas jika pilihan cookie dilumpuhkan.',
  Datadestruction: "Kemusnahan data",
  Description14: "Pelupusan selamat diperlukan untuk semua media yang berkaitan dengan tapak pengeluaran perjudian interaktif Syarikat, tanpa mengira maklumat yang terkandung di dalamnya.",
  DataAccessandUpdates: "Akses Data dan Kemas Kini",
  Description15:
    "Pengguna boleh pada bila-bila masa membuat permintaan bertulis untuk mendapatkan salinan maklumat peribadi mereka yang dipegang oleh kami kepada alamat yang ditunjukkan di bawah. Kami akan mendedahkan kepada anda data peribadi anda yang dipegang oleh kami. Anda juga boleh memberitahu kami tentang kemas kini, pindaan dan pembetulan kepada maklumat pengguna yang dikumpulkan sebelum ini dengan menghubungi Sokongan Pelanggan kami. Selanjutnya, atas permintaan anda, kami akan memadamkan maklumat pengguna seperti yang digariskan di atas pada pangkalan data kami; Walau bagaimanapun, mungkin tidak dapat memadamkan kemasukan anda tanpa maklumat baki yang tersisa kerana sokongan dan rekod penghapusan.Mega888 berhak mengubah atau meminda Dasar Privasi ini pada bila-bila masa. Semua pengubahsuaian sedemikian akan diposkan dalam Dasar Privasi ini. Pelanggan bertanggungjawab untuk menyemak dasar Privasi secara berkala di halaman ini.",
  Contact: "Hubungi",
  Description16: "Sekiranya anda mempunyai sebarang pertanyaan atau komen mengenai dasar ini, sila hubungi kami melalui Sokongan Pelanggan kami, butiran yang boleh didapati di bahagian Hubungi Kami dan Bantuan di Laman Web.",
};
