import httpClient, { TenantId } from "./HttpClient";
import { ClientTenantInfoResponse } from "@/types/Tenant/Response/ClientTenantInfoResponse";

const getTenantClients = (): Promise<ClientTenantInfoResponse[]> => {
	const endPoint = `/api/Tenants/Clients/${TenantId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

export default {
	getTenantClients,
};
