<template>
  <b-container fluid class="runningTextBg" v-b-modal="'runningtext-modal'">
    <div class="runningTextContent">
      <Vue3Marquee class="marquee" :duration="30" :pause-on-hover="true" :pause-on-click="true">
        <span v-for="(runningText, index) in getRunningText" :key="`runningText-${index}`" class="runningTextItem">{{
          runningText
        }}</span>
      </Vue3Marquee>
    </div>
  </b-container>

  <RunningTextModal :runningTextList="runningTextList"></RunningTextModal>
</template>

<script>
import RunningTextModal from "@/components/modal/RunningTextModal.vue";
export default {
  components: {
    RunningTextModal,
  },
  data() {
    return {
      runningTextList: [],
    };
  },
  watch: {
    "$i18n.locale": function (newLanguage) {
      this.runningTextList = this.$store.getters["announcement/getRunningText"](newLanguage);
    },
  },
  computed: {
    getRunningText() {
      return this.runningTextList;
    },
  },
  async created() {
    await this.$store.dispatch("announcement/fetchRunningText").then(() => {
      this.runningTextList = this.$store.getters["announcement/getRunningText"]();
    });
  },
};
</script>

<style scoped>
.runningTextBg {
  height: 34px;
  background: #000000;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.runningTextContent {
  color: #ffffff;
  align-items: center;
  display: block;
  width: 100%;
}
.runningTextItem {
  margin: 0px 3rem;
}
</style>
