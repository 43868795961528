<template>
	<div class="ms-5 my-1" style="background-color: #001f59">
		<Carousel
			v-if="renderPlayerWonCarousel"
			id="playerCarousel"
			class="bannerMax"
			:autoplay="4000"
			:wrap-around="true"
			pause-autoplay-on-hover
			:breakpoints="breakpoints"
		>
			<Slide v-for="playerItem in playerWonList" :key="playerItem">
				<div class="rankImg"></div>
				<div class="row" style="padding: 10px; background: transparent; text-align: left">
					<div class="col-12">
						<div class="playerLabel">{{ playerItem.username }}</div>
						<div class="playerLabel" style="padding-left: 5px !important">{{ $t("HomePlayerWon.WIN") }}</div>
					</div>
					<div class="col-12">
						<div class="playerLabel" style="color: #00bfef">{{ playerItem.rm }}</div>
						<div class="playerLabel" style="padding: 0px 5px !important">{{ $t("HomePlayerWon.IN") }}</div>
						<div class="playerLabel" style="text-decoration: underline">{{ playerItem.game }}</div>
					</div>
				</div>
			</Slide>
			<template #addons> </template>
		</Carousel>
	</div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
export default {
	components: {
		Carousel,
		Slide,
	},
	data() {
		return {
			playerWonList: [],
			renderPlayerWonCarousel: false,
			breakpoints: {
				300: {
					itemsToShow: 2,
					snapAlign: "start",
				},
				700: {
					itemsToShow: 3,
					snapAlign: "start",
				},
				1024: {
					itemsToShow: 4,
					snapAlign: "start",
				},
			},
		};
	},
	methods: {
		async getTopDeposit() {
			await this.$store.dispatch("topdata/fetchTopData", { type: 4 }).then(() => {
				this.playerWonList = this.$store.getters["topdata/getTopData"];
				if (this.playerWonList.length > 0) {
					this.reloadHomePlayerCarousel();
				}
			});
		},
		reloadHomePlayerCarousel() {
			this.renderPlayerWonCarousel = false;
			this.$nextTick(() => {
				this.renderPlayerWonCarousel = true;
			});
		},
	},
	created() {
		this.getTopDeposit();
	},
	computed: {},
	watch: {},
};
</script>
<style scoped>
.playerLabel {
	padding: 0px !important;
	display: inline-block;
}
.rankImg {
	background-image: url("@/assets/Winner.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 60px;
	height: 40px;
}

@media (max-width: 767px) {
	.playerLabel {
		font-size: 0.8rem !important;
	}
	#playerCarousel div.playerLabel {
		font-size: 11px !important;
	}
}
</style>
