// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/TopStar.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/Top1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/Top2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/Top3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reportTitle[data-v-be54e598]{color:#00cefc;font-size:24px}#WINNER[data-v-be54e598]{background:#002c8b!important}.realTimeTable>tbody[data-v-be54e598]{color:#fff;background:transparent}.realTimeTable>thead[data-v-be54e598]{color:#f8eb4c;background:transparent}.realTimeTable>tbody>tr>td[data-v-be54e598]{vertical-align:middle;border:none;width:-moz-min-content;width:min-content}.rankImg[data-v-be54e598]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;width:30px;height:30px}.reportContainer[data-v-be54e598]{background:#001942;border-top:5px solid #00cefc;border-radius:20px;padding:20px}.realtimeDate[data-v-be54e598],.realtimeUserId[data-v-be54e598]{text-align:left}.realtimeRM[data-v-be54e598]{text-align:right;color:#36ff70}.realtimeDate[data-v-be54e598]{color:#7285a7}.realTimeTable>tbody tr:first-child>td:first-child>.rankImg[data-v-be54e598]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.realTimeTable>tbody tr:nth-child(2)>td:first-child>.rankImg[data-v-be54e598]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.realTimeTable>tbody tr:nth-child(3)>td:first-child>.rankImg[data-v-be54e598]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
