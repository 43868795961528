<template>
  <b-modal id="lang-modal" size="md" v-model="modalShow" hide-footer hide-header class="default-modal">
  	<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="position:absolute; top:10px; right:10px; padding: 0.5rem 0.5rem; margin: -0.5rem -0.5rem -0.5rem auto"></button>
		<b-container class="px-5">
      <b-row>
        <div style="display: flex; justify-content: space-around; flex-wrap: wrap">
          <div v-for="item in clientlanguagelist" :key="(item,i)">
            <b-button variant="lang" block @click="changeLang(item.languageCountryCode.substring(0, 2))">
              <base-image :imgSrc="langs[item.languageCountryCode.substring(0, 2) + 'Img']" mode="lang" :isFullUrl="true"></base-image>
            </b-button>
            <div style="text-align:center">{{$t("LangModal." + item.languageCountryCode.substring(0, 2).charAt(0).toUpperCase() + item.languageCountryCode.substring(0, 2).slice(1))}}</div>
          </div>
          <!-- <div>
            <b-button variant="lang" block @click="changeLang('ms')">
              <base-image :imgSrc="msImg" mode="lang" :isFullUrl="true"></base-image>
            </b-button>
            <div style="text-align:center">{{$t("LangModal.Ms")}}</div>
          </div>
          <div> 
            <b-button variant="lang" block @click="changeLang('zh')">
              <base-image :imgSrc="zhImg" mode="lang" :isFullUrl="true"></base-image>
            </b-button>
            <div style="text-align:center">{{$t("LangModal.Zh")}}</div>
          </div> -->
        </div >
      </b-row>
    </b-container>
	</b-modal>
</template>

<style scoped>
.lang {
  width: 100px !important;
  height: auto;
  max-height: 221px;
}
</style>

<script>
export default {
  data() {
    return {
      langs:{
        enImg: process.env.VUE_APP_Media_Domain + "mega888/assets/images/NavBar/en.svg",
      msImg: process.env.VUE_APP_Media_Domain + "mega888/assets/images/NavBar/ms.svg",
      zhImg: process.env.VUE_APP_Media_Domain + "mega888/assets/images/NavBar/zh.svg",
      },
      
      modalShow: false,
      clientlanguagelist: []
    };
  },
  computed: {},
  components: {},
  methods: {
    changeLang(lang) {
      this.$root.$i18n.locale= lang;
      localStorage.setItem("locale", lang);
      this.modalShow = false;
    },  
  },
  async created() {
    await this.$store.dispatch("language/fetchClientLanguageList").then(() => {
       this.clientlanguagelist = this.$store.getters["language/getClientLanguage"];      
    });
  },
};
</script>
