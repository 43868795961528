import httpClient from "./HttpClient";
import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { AutoDepositRequest } from "@/types/Payment/Request/AutoDepositRequest";
import { WithdrawalRequest } from "@/types/Payment/Request/WithdrawalRequest";
import { TransferRequest } from "@/types/Payment/Request/TransferRequest";
import { CompleteTransferRequest } from "@/types/Payment/Request/CompleteTransferRequest";
import { DepositResultResponse } from "@/types/Payment/Response/DepositResultResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";
import { TransferResultDto } from "@/types/Payment/Response/TransferResultResponse";
import { CompleteTransferResponse } from "@/types/Payment/Response/CompleteTransferResponse";

const addDeposit = (
	loginId: string,
	amount: number,
	to: string,
	bonusID: number,
	uniqueCodeID: number
): Promise<DepositResultResponse> => {
	const endPoint = `/api/SingleWalletPayment/AddDepositTicket`;
	const request: AutoDepositRequest = {
		loginID: loginId,
		amount: amount,
		to: to,
		method: "AutoDeposit",
		reference: null,
		bonusID: bonusID,
		uniqueCodeID: uniqueCodeID,
		needNoms: true,
		ipAddress: "",
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const addManualDeposit = (
	loginId: string,
	amount: number,
	to: string,
	channel: string,
	receipt: File,
	bonusID: number,
	reference: string
): Promise<DepositResultResponse> => {
	Object.assign(httpClient.defaults, { headers: { "Content-Type": "multipart/form-data" } });
	const formData = new FormData();
	formData.append("LoginID", loginId);
	formData.append("Amount", amount.toString());
	formData.append("To", to);
	formData.append("Reference", reference);
	formData.append("BonusID", bonusID.toString());
	formData.append("Channel", channel);
	formData.append("Receipt", receipt);
	const endPoint = `/api/SingleWalletPayment/AddManualDepositTicket`;
	const result = httpClient.post(endPoint, formData).then((response) => response.data);
	Object.assign(httpClient.defaults, { headers: { "Content-Type": "application/json" } });
	return result;
};

const addWithdrawal = (
	loginId: string,
	amount: number,
	to: string,
	accountNumber: string,
	accountHolder: string
): Promise<DepositResultResponse> => {
	const endPoint = `/api/SingleWalletPayment/AddWithdrawalTicket`;
	const request: WithdrawalRequest = {
		loginID: loginId,
		amount: amount,
		to: to,
		accountHolder: accountHolder,
		accountNumber: accountNumber,
		ipAddress: "",
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const addTransfer = (loginID: string, vendorFrom: string, vendorTo: string): Promise<TransferResultDto> => {
	const endPoint = `/api/SingleWalletPayment/AddTransferTicket`;
	const request: TransferRequest = {
		loginID: loginID,
		vendorFrom: vendorFrom.toString(),
		vendorTo: vendorTo.toString(),
		ipAddress: "",
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const completeTransfer = (ticketId: number): Promise<CompleteTransferResponse> => {
	const endPoint = `/api/SingleWalletPayment/CompleteTransferTicket`;
	const request: CompleteTransferRequest = {
		ticketId: ticketId,
	};

	return httpClient.put(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getStatement = (loginId: string, type: string, from?: Date, to?: Date): Promise<StatementResponse[]> => {
	const endPoint = `/api/Payment/GetMemberTicketList`;
	const params = {
		loginId: loginId,
		type: type,
		from: from,
		to: to,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getTransferStatement = (loginId: string, from?: Date, to?: Date): Promise<StatementResponse[]> => {
	const endPoint = `/api/Payment/GetTransferTicketList`;
	const params = {
		loginId: loginId,
		from: from,
		to: to,
		tenantId: process.env.VUE_APP_IdentityServer_TenantId,
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getBonusReferral = (id: string): Promise<BonusReferralResponse[]> => {
	const endPoint = `/api/Bonus/Referral/${id}`;
	const params = {
		id: id,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getStatement,
	addDeposit,
	addManualDeposit,
	addWithdrawal,
	addTransfer,
	completeTransfer,
	getBonusReferral,
	getTransferStatement,
};
