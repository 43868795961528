<template>
	<b-button class="navbar-toggler d-md-none" type="button" @click="isOpen = true" variant="none">
		<i class="fa fa-bars fa-2x"></i>
	</b-button>
	<Slide
		class="sidebarContainer"
		:isOpen="isOpen"
		:burgerIcon="false"
		:crossIcon="false"
		:closeOnNavigation="true"
		@closeMenu="isOpen = false"
		noOverlay
	>
		<div class="sidebarContainer px-0" id="page-wrap">
			<ul class="navbar-nav justify-content-end flex-grow-1 p0">
				<div v-for="navbar in navbarList" :key="navbar.title">
					<template v-if="!navbar.ignoreMaintenance && isMaintenance && isLogin">
						 <a class="nav-item d-flex" role="button" @click="maintenancepopup">  
							<div class="navIconContent">
									<font-awesome-icon :icon="navbar.icon" class="navIcon" />
								</div>
                  			<div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>      
             			</a> 
					</template>
					<template v-else-if="navbar.isRouteLink === true && navbar.isPopUp === false">
						<router-link class="nav-item d-flex" :to="navbar.link">
							<div class="navIconContent">
								<font-awesome-icon :icon="navbar.icon" class="navIcon" />
							</div>
							<div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
						</router-link>
					</template>
					<template v-else-if="navbar.isRouteLink === false && navbar.isPopUp === true">
						<a class="nav-item d-flex" role="button" v-if="!navbar.afterLogin || isLogin" v-b-modal="navbar.link">
							<div class="navIconContent">
								<font-awesome-icon :icon="navbar.icon" class="navIcon" />
							</div>
							<div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
						</a>
					</template>
					<template v-else>
						<a class="nav-item d-flex" role="button" @click="doPopUp(navbar.link)" v-if="!navbar.afterLogin || isLogin">
							<div class="navIconContent">
								<font-awesome-icon :icon="navbar.icon" class="navIcon" />
							</div>
							<div class="nav-title">{{ $t("SideBar." + navbar.title) }}</div>
						</a>
					</template>
				</div>
			</ul>
		</div>
	</Slide>
</template>

<style scoped>
.sidebarContainer {
	background-color: #001f59 !important;
}
.nav-item {
	padding: 0.5rem 0px;
	color: #00cefc !important;
	text-decoration: none;
}

.router-link-active.nav-item,
.router-link-exact-active.nav-item {
	background: #001942;
	color: #00cefc !important;
}

.navIconContent {
	margin: 0 1rem 0 2rem;
	width: 1.5rem;
}

.nav-title {
	margin-left: 1rem;
}
</style>
<style>
.bm-menu {
	padding-top: 0px;
	background-color: #001f59 !important;
}
.bm-menu .bm-item-list {
	margin-left: 0px;
	padding-bottom: 60px;
	font-size: 1rem;
}
</style>
<script>
import { navBar } from "@/constant";
import { Slide } from "vue3-burger-menu";

export default {
	data() {
		return {
			logoUrl: "mega888/assets/images/NavBar/mega888.png",
			isOpen: false,
		};
	},
	components: {
		Slide,
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		navbarList() {
			var navbarFiltered = navBar.filter((i) => i.view.includes("mobile"));
			if (!this.isLogin) {
				navbarFiltered = navbarFiltered.filter((i) => i.afterLogin == false);
			}
			return navbarFiltered;
		},
		isMaintenance(){
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
	},
	methods: {
		doPopUp(popUpName) {
			if (popUpName == "logout") {
				this.logOut();
			} else {
				//console.log(popUpName);
			}
		},
		logOut() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.$store.dispatch("identityServer/logOut");
			this.toast.success(this.$t("HeaderLogin.LogoutSuccess"), {
				position: toastPosition,
				timeout: 2000,
				onClose: () => this.redirectHomePage(),
			});
		},
		redirectHomePage() {
			window.location.href = "/";
		},
		closeSidebar() {
			this.$root.$emit("bv::toggle::collapse", "offcanvasNavbar");
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		}
	},
};
</script>
