<template>
	<b-container fluid class="downloadBg" :style="{ backgroundImage:  'url(' + downloadBackground + ')' }">
		<b-container class="h-100">
			<b-row>
				<b-col lg="7" sm="0"></b-col>
        		<b-col lg="5" sm="12" class="qrContainer">
          			<div>
            			<div class="homeDownloadTitle">{{ $t("HomeDownload.DOWNLOADNOW") }}</div>
						<div class="homeDownloadDesc">{{ $t("HomeDownload.Description1") }}.</div>
						<div class="homeDownloadDesc">{{ $t("HomeDownload.Description2") }}.</div>
						<DownloadQR></DownloadQR>
          			</div>
        		</b-col>
      		</b-row>
		</b-container>
	</b-container>
</template>
<script>
import DownloadQR from "@/components/content/Download/DownloadQRHome.vue";
export default {
	data() {
		return {
			downloadBackground: process.env.VUE_APP_Media_Domain + "mega888/assets/images/Home/DownloadBg.png",
		};
	},
	components: {
		DownloadQR,
	},
};
</script>
<style scoped>
.downloadBg {
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center;
}
.homeDownloadTitle {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	text-align: center;
	color: #ffaa00;
}
.homeDownloadDesc {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	color: #ffffff;
}
.qrContainer{
	padding:40px;
}

@media (max-width: 991px) {
	.downloadBg {
		background: unset !important;
	}
	.qrContainer{
		padding:0px !important;
	}
}
</style>
