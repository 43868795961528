export default {
	BankInfo: "Bank Info",
	BankName: "Bank Name",
	AccountHolder: "Account Holder",
	AccountNumber: "Account Number",
	RequestTAC: "Request TAC",
	Add: "Add",
	AccountHolderRequired: "*Account Holder Is Required",
	AccountNumberRequired: "*Account Number is Required",
	TACCode: "TAC Code",
	accountNameMin: "*Account Holder minimum length is {minLength}",
	accountNameMax: "*Account Holder maximum length is {maxLength}",
	accountNumberMax: "*Account Number maximum length is {minAccNoLength}",
	accountNumberMin: "*Account Number minimum length is {maxAccNoLength}",
	SubmitSuccess: "Submit Success",
	SubmitError: "Submit Error",
	VerifyTACError: "Verify TAC Error",
	RequestTACSuccess: "Request TAC Success",
	RequestTACFailed: "Request TAC Failed",
	tacLength: "*TAC length is {tacLength}",
	TACRequired: "*TAC Code is Required",
	DuplicateBank: "Invalid Bank Account",
};
