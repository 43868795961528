import { TopDataStateInterface } from "./state";
import { TopDataResponse } from "@/types/TopData/Response/TopDataResponse";
import { MutationTree } from "vuex";

export enum TopDataMutations {
	SET_TOPDATA = "SET_TOPDATA",
}

export const mutations: MutationTree<TopDataStateInterface> = {
	[TopDataMutations.SET_TOPDATA](state, payload: TopDataResponse[]) {
		state.topdata = payload;
	},
};
