<template>
  <b-row>
    <b-col cols="6" class="my-2 p-1">
      <div>{{ $t("DownloadQR.Android") }}</div>
      <qrcode-vue :value="androidDownloadUrl" level="H" class="my-2 p-3 w-75 h-auto mx-auto d-none d-md-block" />
      <base-image
        :imgSrc="androidQrUrl"
        mode="QrImage mx-auto d-block d-md-none"
        :isFullUrl="true"
        fluid-grow
      ></base-image>
      <b-button variant="downloadGame" @click="downloadGame(androidDownloadUrl)">{{
        $t("DownloadQR.Download")
      }}</b-button>
    </b-col>
    <b-col cols="6" class="my-2 p-1">
      <div>{{ $t("DownloadQR.IOS") }}</div>
      <qrcode-vue :value="iosDownloadUrl" level="H" class="my-2 p-3 w-75 h-auto mx-auto d-none d-md-block" />
      <base-image :imgSrc="iosQrUrl" mode="QrImage mx-auto d-block d-md-none" :isFullUrl="true" fluid-grow></base-image>
      <b-button variant="downloadGame" @click="downloadGame(iosDownloadUrl)">{{ $t("DownloadQR.Download") }}</b-button>
    </b-col>
  </b-row>
</template>

<style scoped>
.btn-downloadGame {
  height: 39px;
  width: 60%;
  background: linear-gradient(90deg, rgba(0, 95, 196, 1) 0%, rgba(0, 153, 199, 1) 100%);
  border-radius: 19.5px !important;
  color: #fff;
}

.btn-downloadGame:hover {
  color: #00bfef !important;
  filter: brightness(100%) !important;
}

@media (max-width: 767px) {
  .btn-downloadGame {
    width: 50% !important;
  }
}
.QrImage {
  width: 50% !important;
  height: auto;
  margin: 1rem 0;
}
</style>
<script>
import QrcodeVue from "qrcode.vue";
import BaseImage from "@/components/dynamic/BaseImage.vue";
export default {
  data() {
    return {
      androidQrUrl: require("@/assets/android.png"),
      iosQrUrl: require("@/assets/ios.png"),
      iosDownloadUrl: "",
      androidDownloadUrl: "",
    };
  },
  methods: {
    downloadGame(downloadUrl) {
      window.open(downloadUrl, "_blank");
    },
  },
  components: {
    QrcodeVue,
    BaseImage,
  },
  async created() {
    await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { vendorId: 30, downloadType: "ANDROID" });
    await this.$store.dispatch("downloadLinks/fetchDownloadLinks", { vendorId: 30, downloadType: "IOS" });
    this.androidDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("ANDROID").downloadUrl;
    this.iosDownloadUrl = this.$store.getters["downloadLinks/getDownloadLinks"]("IOS").downloadUrl;
  },
};
</script>
