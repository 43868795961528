<template>
	<b-row align-h="center" class="navContainer d-none d-md-flex">
		<div class="navContent" :style="{ backgroundImage: 'url(' + navbarBackground + ')' }">
			<nav>
				<NavBarLink> </NavBarLink>
			</nav>
		</div>
	</b-row>
</template>

<style scoped>
nav {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 14px 80px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}

.navContainer {
	max-width: 768px;
	top: 80px;
	position: absolute;
	z-index: 999;
	transform: translateX(-50%);
	left: 50%;
	width: fit-content;
}

.navContent {
	width: 40vw;
	max-height: 105px;
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: 90% 100%;
}

@media (max-width: 991px) {
	.navContent {
		width: 50vw;
		padding-left: 5px;
		padding-right: 5px;
	}

	.navContainer {
		top: 100px !important;
	}
}

@media (max-width: 767px) {
	.navContent {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	nav {
		padding: 10px 0px 2px;
	}
}
</style>

<script>
import NavBarLink from "@/components/content/Header/HeaderNavLink";
export default {
	components: {
		NavBarLink,
	},
	computed: {},
	data() {
		return {
			navbarBackground: process.env.VUE_APP_Media_Domain + "mega888/assets/images/NavBar/NavBarBox.png",
		};
	},
};
</script>
