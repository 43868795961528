export default {
	Username: "游戏账号",
	Password: "游戏密码",
	Copy: "复制",
	CopySuccess: "复制成功",
	CopyError: "复制失败",
	Update: "更新",
	Save: "保存",
	newPasswordRequired: "*请填写新密码",
	ChangePasswordSuccess: "成功更新密码",
	ChangePasswordError: "更新密码失败",
	GamePassword: "游戏密码 : *请使用网站登录密码",
};
