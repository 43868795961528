import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { TopDataStateInterface } from "./state";
import { TopDataResponse } from "@/types/TopData/Response/TopDataResponse";

export const getters: GetterTree<TopDataStateInterface, RootState> = {
	getTopData(state): TopDataResponse[] {
		return state.topdata;
	},
};
