import { ActionTree } from "vuex";
import { getTopData } from "@/network/TopDataService/TopData";
import { TopDataStateInterface } from "./state";
import { RootState } from "@/store/state";
import { TopDataMutations } from "./mutations";

export const actions: ActionTree<TopDataStateInterface, RootState> = {
	async fetchTopData(context, payload) {
		const res = await getTopData(payload.type);
		context.commit(TopDataMutations.SET_TOPDATA, res);
	},
};
