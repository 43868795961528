<template>
  <div class="event-main" v-if="isShow">
    <div class="event-dialog">
      <div class="event-content">
        <div v-if="currentEventItem[0].fileName">
          <base-image
            :imgSrc="currentEventItem[0].fileName"
            mode="mx-auto"
            :isFullUrl="true"
            fluid-grow
            class="event-image"
          ></base-image>
        </div>
        <div v-html="currentEventItem[0].content" class="my-4 mx-5"></div>
        <input type="checkbox" id="AnnouncementCheckBox" v-model="checkNotShow" />
        <label for="AnnouncementCheckBox" style="color: white; cursor: pointer; margin: 0.5rem; display: inline">
          {{ $t("EventView.DoNotShow") }}
        </label>
        <br />
        <b-button variant="secondary" class="mt-3 mb-5" @click="closeEvent">{{ $t("EventView.Close") }}</b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.event-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  background: rgba(0, 0, 0, 0.9);
}
.event-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  color: #ffffff;
  top: 5rem;
}
.event-content {
  position: relative;
  width: 100%;
  pointer-events: auto;
  z-index: 1051;
  overflow-y: auto;
}
.event-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
  width: auto !important;
}
</style>

<script>
import { getDefaultCultureCode, getCultureID } from "@/currentCulture";
import BaseImage from "@/components/dynamic/BaseImage.vue";
export default {
  data() {
    return {
      isShow: false,
      eventItem: [],
      currentEventItem: [],
      annoucementShow: false,
      checkNotShow: false,
    };
  },
  components: {
    BaseImage,
  },
  methods: {
    async fetchEventItem() {
      await this.$store.dispatch("announcement/fetchAnnouncement", this.isLoginString).then(() => {
        this.eventItem = this.$store.getters["announcement/getAnnouncement"];
        this.filterEventItem();
      });
    },
    filterEventItem() {
      const cultureCode = getDefaultCultureCode();
      const currentLocaleID = getCultureID(cultureCode);
      if (this.isLogin) {
        this.annoucementShow = this.$cookies.get("afterLoginShowAnnouncement");
      } else {
        this.annoucementShow = this.$cookies.get("beforeLoginShowAnnouncement");
      }

			this.currentEventItem = this.eventItem.filter((x) => x.langId == currentLocaleID);
			if (this.currentEventItem.length > 0 && !this.annoucementShow) {
				if (this.currentEventItem[0].status) {
					this.isShow = true;
					document.body.classList.add("modal-open");
				}
			}
		},
		closeEvent() {
			if (this.checkNotShow) {
				if (this.isLogin) {
					this.$cookies.set("afterLoginShowAnnouncement", true, "1d");
				} else {
					this.$cookies.set("beforeLoginShowAnnouncement", true, "1d");
				}
			}
			this.checkNotShow = false;
			this.isShow = false;
			document.body.classList.remove("modal-open");
		},
	},
	computed: {
		onRefresh() {
			return this.$store.getters["identityServer/getOnRefresh"];
		},
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		isLoginString() {
			return this.isLogin ? "AfterLogin" : "BeforeLogin";
		},
		isOnSubmitLogin() {
			return this.$store.getters["identityServer/getOnSubmitLogin"];
		},
	},
	watch: {
		onRefresh(isRefresh) {
			if (!isRefresh) {
				this.fetchEventItem();
			}
		},
		isOnSubmitLogin(isSubmitLogin) {
			if (isSubmitLogin) {
				this.fetchEventItem();
			}
		},
	},
};
</script>
