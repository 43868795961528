<template>
	<HomeBanner></HomeBanner>
	<!-- <AnnouncementView></AnnouncementView> -->
	<HomePlayer></HomePlayer>
	<HomeSwitchSite></HomeSwitchSite>
	<HomeFastPayment></HomeFastPayment>
	<!-- <HomeJackpot></HomeJackpot> -->
	<HomePromotion></HomePromotion>
	<HomeWithdrawalChart></HomeWithdrawalChart>
	<HomeReport></HomeReport>
	<HomeDownload></HomeDownload>
	<event-view></event-view>
</template>
<style>
.homeTitle {
	color: #ffaa00;
	font-family: Montserrat;
	font-size: 24px;
	font-weight: 500;
	display: flex;
	justify-content: center;
}
</style>
<script>
// import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
import HomePlayer from "@/components/content/Home/HomePlayerWon.vue";
import HomeFastPayment from "@/components/content/Home/HomeFastPayment.vue";
// import HomeJackpot from "@/components/content/Home/HomeJackpot.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomeWithdrawalChart from "@/components/content/Home/HomeWithdrawalChart.vue";
import HomePromotion from "@/components/content/Home/HomePromotion.vue";
import HomeDownload from "@/components/content/Home/HomeDownload.vue";
import HomeReport from "@/components/content/Home/HomeReport.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";
import EventView from "@/components/content/Event/EventView.vue";

export default {
	data() {
		return {};
	},
	components: {
		HomeBanner,
		// AnnouncementView,
		HomeFastPayment,
		// HomeJackpot,
		HomeWithdrawalChart,
		HomePromotion,
		HomeDownload,
		HomeReport,
		HomePlayer,
		HomeSwitchSite,
		EventView,
	},
	methods: {},
};
</script>
