import { TopDataResponse } from "@/types/TopData/Response/TopDataResponse";

export interface TopDataStateInterface {
	topdata: TopDataResponse[];
}

function state(): TopDataStateInterface {
	return {
		topdata: [],
	};
}

export default state;
