import httpClient, { ClientId } from "./HttpClient";
import { MemberResponse } from "@/types/Member/Response/MemberResponse";
import { MemberBankInfoResponse } from "@/types/Member/Response/MemberBankInfoResponse";
import { AddMemberBankRequest } from "@/types/Member/Request/AddMemberBankRequest";
import { UpdatePasswordRequest } from "@/types/Member/Request/UpdatePasswordRequest";
import { ResetPasswordRequest } from "@/types/Member/Request/ResetPasswordRequest";
import { UpdateMemberDOBRequest } from "@/types/Member/Request/UpdateMemberDOBRequest";
import { ExchangeTokenRequest } from "@/types/Member/Request/ExchangeTokenRequest";
import { ForgotPasswordRequest } from "@/types/Member/Request/ForgotPasswordRequest";
import { ForgotPasswordResponse } from "@/types/Member/Response/ForgotPasswordResponse";
import { AspUserClientResponse } from "@/types/Member/Response/AspUserClientResponse";
import { ExchangeTokenResponse } from "@/types/Member/Response/ExchangeTokenResponse";
import { AddMemBankResponse } from "@/types/Member/Response/AddMemBankResponse";
import { MemberReferralResponse } from "@/types/Member/Response/MemberReferralResponse";
import { MemberGroupResponse } from "@/types/Member/Response/MemberGroupResponse";
import { MemberCurrentTierInfoResponse } from "@/types/Member/Response/MemberCurrentTierInfoResponse";

const getMember = (loginId: string): Promise<MemberResponse> => {
	const endPoint = `/api/Members/${loginId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getMemberBank = (loginId: string): Promise<MemberBankInfoResponse> => {
	const endPoint = `/api/Members/${loginId}/banks`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const addMemberBank = (
	loginId: string,
	bankId: number,
	accountHolder: string,
	accountNumber: string
): Promise<AddMemBankResponse> => {
	const endPoint = `/api/Members/${loginId}/Banks/${bankId}`;
	const request: AddMemberBankRequest = {
		loginId: loginId,
		bankId: bankId,
		accountHolder: accountHolder,
		accountNumber: accountNumber,
		primary: true,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const changePassword = (loginId: string, password: string, newPassword: string): Promise<any> => {
	const endPoint = `/api/Members/${loginId}/Password`;
	const request: UpdatePasswordRequest = {
		loginId: loginId,
		currentPassword: password,
		newPassword: newPassword,
	};
	return httpClient.patch(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const resetPassword = (loginId: string, token: string, password: string): Promise<any> => {
	const endPoint = `/api/Members/${loginId}/Password`;
	const request: ResetPasswordRequest = {
		loginId: loginId,
		token: decodeURIComponent(token),
		password: password,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const updateMemberDOB = (loginId: string, DOB: Date): Promise<any> => {
	const endPoint = `/api/Members/${loginId}/DOB`;
	const request: UpdateMemberDOBRequest = {
		loginId: loginId,
		dob: DOB,
	};

	return httpClient.patch(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const forgotPassword = (
	loginId: string,
	token: string,
	byPassSms: boolean,
	tokenOTP: string
): Promise<ForgotPasswordResponse> => {
	const endPoint = `/api/Members/${loginId}/Password/Recovery`;
	httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

	const request: ForgotPasswordRequest = {
		loginId: loginId,
		clientId: ClientId,
		byPassSms: byPassSms,
		token: tokenOTP,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const getMemberCount = (token: string): Promise<number> => {
	const endPoint = `/api/Members/count`;

	httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const params = {
		Active: false,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const fetchAspUserClient = (memberId: string, clientId: string): Promise<AspUserClientResponse> => {
	const endPoint = `/api/Members/${memberId}/Clients/${clientId}`;

	return httpClient.get(endPoint).then((response) => response.data);
};

const exchangeToken = (ipAddress: string): Promise<ExchangeTokenResponse> => {
	const endPoint = `/api/Auth/Token/Exchange`;
	Object.assign(httpClient.defaults, { headers: { "X-Origin-IP": ipAddress } });
	const request: ExchangeTokenRequest = {
		clientId: ClientId,
		clientSecret: process.env.VUE_APP_IdentityServer_ClientSecret,
	};
	return httpClient.post(endPoint, request).then((response) => response.data);
};

const getMemberReferral = (id: string): Promise<MemberReferralResponse> => {
	const endPoint = `/api/Commission/GetReferralRequirement/${id}`;
	const params = {
		id: id,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getMemberGroup = (): Promise<MemberGroupResponse[]> => {
	const endPoint = `/api/MemberGroup`;
	const params = {
		//tenantId: process.env.VUE_APP_IdentityServer_TenantId,
		tenantId: "AAE31147-8BC9-409B-8ABA-3BC36529483B",
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getCurrentMemberTierInfo = (memberId: string): Promise<MemberCurrentTierInfoResponse> => {
	const endPoint = `/api/MemberGroup/MemberCurrentTierInfo/${memberId}`;
	const params = {
		memberId: memberId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getMember,
	getMemberBank,
	addMemberBank,
	changePassword,
	updateMemberDOB,
	forgotPassword,
	resetPassword,
	fetchAspUserClient,
	getMemberCount,
	exchangeToken,
	getMemberReferral,
	getMemberGroup,
	getCurrentMemberTierInfo,
};
