import httpClient, {ClientId} from "./HttpClient";
import { RunningTextResponse } from "@/types/Announcement/Response/RunningTextResponse";
import { AnnouncementResponse } from "@/types/Announcement/Response/AnnouncementResponse";

const getRunningText = (status: string, runningTextCategory: string): Promise<RunningTextResponse[]> => {
	const endPoint = "/api/Announcement/RunningText";
	const params = {
		status: status,
		runningTextCategory: runningTextCategory,
		ClientId: ClientId
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

const getAnnouncement = (remark: string): Promise<AnnouncementResponse> => {
	const endPoint = "/api/Announcement";
	const params = {
		remark: remark,
		ClientId: ClientId
	};

	return httpClient.get(endPoint, { params }).then((response) => response.data);
};


export { getRunningText, getAnnouncement };
