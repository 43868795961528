export default {
  DOWNLOADNOW: "MUAT TURUN",
  Description1: "Alami permainan kasino dalam talian yang terbaik",
  Description2: "Imbas dan main sekarang",
  Title: "Permainan yang terdapat dalam MEGA888",
  Description3: "Anda boleh menikmati permainan papan dan slot yang berkualiti tinggi di MEGA888. Tambahan pula, kami bekerja di lapangan dengan pembekal utama-RealTimeGaming(RTG).Ia adalah jenama yang terkenal dalam industri kasino di seluruh dunia. Maka, yakinlah bahawa permainan kami memenuhi keperluan anda. Jika anda berfikir anda mungkin hanya melalui sistem perjudian yang tidak adil, usahlah risau. Lagipun, MEGA888 hanya bekerjasama dengan pembangun permainan yang mempunyai kandungan yang sah pada 100 peratus. Sila jangan percaya bahawa kasino adalah bertentangan dengan anda atau cuba untuk mengelakkan anda daripada menang. Matlamat kami adalah untuk memuaskan pelanggan kami.",
  Description4: "Bilangan permainan kasino dalam talian yang sedia untuk dimain di MEGA888 adalah banyak selagi anda bermain di Mega888 setiap bulan, ia adalah jarang bagi mana-mana pemain kehabisan pilihan. Kandungan hiburan MEGA888 Malaysia terdapat dalam pelbagai jeni, dari permainan manangkap ikan dalam talian ke permainan slot dalam talian ke pelbagai permainan meja seperti IDN Poker,bakarat dan rolet. Apa jua permainan kasino dalam talian yang anda cari, ia terdapat dalam MEGA888.",
  Description5: "Keluaran baharu 2020 termasuklah:",
  Game1: "Wolf Hunter",
  Game1Description: "Wolf Hunter ialah sebuah permainan slot yang cantik dengan grafik gred AAA. Permainan ini mempunyai gaya era Viktoria gothic dan mengisahkan kisah seorang pemburu yang keluar memburu serigala pada waktu malam. Ia bukanlah serigala biasa tetapi serigala jadian.Permainan ini mempunyai elemen seram yang menegekalkan permainan ini seronok dan mendebarkan.",
  Game2: "Motosikal",
  Game2Description: "Semua orang adalah untuk anda, untuk anda yang ketagihan kelajuan di luar sana! Motosikal ialah suatu pengalaman yang mengepam adrenalin dalam permainan slot yang seumpama dengan kegilaan Car Racing, salah satu permainan slot yang viral dan trend teratas di kawasan ini.",
  Game3: "Dragon Maiden",
  Game3Description: "Masuki tanah fantasi Dragon Maidens di mana anda boleh memilih untuk menjinakkan atau membunuh naga. Ini adalah permainan slot yang meletakkan keutamaan dalam elemen tradisi dan bercerita, mejadikan ia salah satu pengalaman permainan slot yang menarik dan mengujakan.",
  Game4: "Steam Tower",
  Game4Description: "Steam Tower ialah permainan slot yang berdasarkan steampunk yang mengisahkan cerita seorang detektif yang kesunyian cuba untuk menaiki menara stim dengan susahnya untuk menyelamatkan gadis. Dia mesti bersemuka dengan semua jenis ketabahan dan bahaya untuk sampai ke atas, daripada raksasa, naga hingga perusuh. Senjata pilihannya ialah senapang harpoon yang hebat. Jika anda menyukai permainan seperti steampunk, misalnya, Bioshock dan System Shock, maka, permainan ini sudah pastinya untuk anda.",
  DownloadTitle : "Bagaimana untuk memuat turun MEGA888",
  iosDownload : "Muat turun iOS",
  AndroidDownload : "Muat Turun Android",
  iosDownloadDesription: "Sebagai pengguna iOS, tumpukan perhatian kepada butir-butir, memasang aplikasi pihak ketiga dalam peranti Apple adalah sedikit rumit, tetapi ia boleh dibuat dengan mudah jika langkah-langkah ini diiikuti.",
  AndroidDownloadDesription: "Muat turun Android adalah mudah dan lebih mudah; walau bagaimanapun, kami akan menerangkannya kepada mereka yang tidak digunakan untuk mengemaskini aplikasi pihak ketiga.",
  iosDownloadSteps1: "1. Buka pelayar laman web anda dan masukkan 'http://www.mymega888.vip/Download'. Ia akan membawa anda terus ke laman web ini, suatu laman web yang boleh dipercayai di mana anda boleh memuat turun fail aplikasi.",
  iosDownloadSteps2: "2. Untuk Apple iPhone anda, pilih aplikasi edisi iOS.",
  iosDownloadSteps3: "3. Sila tunggu untuk selesai muat turun. Ketika menu pop-up menunjukkan pasang.",
  iosDownloadSteps4: "4. Ketika notifikasi 'Muat Turun Berjaya' kelihatan dalam computer anda, tekan ikon fail aplikasi 'MEGA888' dan tunggu untuk pop-up 'Pembangun Perusahaan yang Tidak Dipercayai' mesej ralat ini muncul disebabkan laman web ini adalah tidak asal. iOS tidak membenarkan muat turun Sumber yang Tidak Dipercayai.",
  iosDownloadSteps5: "5. Seterusnya, klik butang 'Batal,' pada komputer iOS anda untuk meninggalkan tab pelayan anda, kemudian, buka menu 'Tetapan'. Pilih pilihan 'Pengurusan Sistem' di bawah 'Umum' dan periksa untuk 'All Continental Trading Sdn. Trust All Continental Trading Sdn.' Dan tekan highlight. Bhd.' dan pengesahan 'Trust'.",
  iosDownloadSteps6: "6. Sebab untuk semua ini adalah kerana peranti iOS mempunyai firewall terbina dalam yang mengelakkan laman web yang tidak dipercayai daripada mengakses dan menggunakan fail dan aplikasi. Program MEGA888 akan memintas firewall dengan cara ini, dengan suatu perubahan nama. Setelah memasang program, anda boleh menikmati aplikasi tersebut secara percuma.",
  AndroidDownloadSteps1: "1. Buka pelayan anda dan masukkan 'http://www.mymega888.vip/Download'",
  AndroidDownloadSteps2: "2. Pilih pilihan Android. Anda akan digesa untuk membenarkan muat turun fail apk, meluluskannya dan akhirnya menunggu untuk memuat turun.",
  AndroidDownloadSteps3: "3. Setelah memuat turun apk, pilih untuk memasangnya dari pilihan muat turun.",
  AndroidDownloadSteps4: "4. Anda perlu membenarkan pilihan ini dalam Tetapan untuk memasang peranti pihak ketiga ini.",
  AndroidDownloadSteps5: "5. Tekan butang 'Tetapan' dalam telefon anda anda skrol pada bahagian 'Privasi' dalam model android yang kemudiannya, ia juga merupakan 'sumber tersembunyi'. Apa sahaja cara yang muncul adalah pilihan yang ingin anda sunting.",
  AndroidDownloadSteps6: "6. Klik 'benarkan' pilihan ini untuk memasang daripada sumber aplikasi yang bukan stor Google Play.",
  AndroidDownloadSteps7: "7. Klik 'Pasang.' Sejak anda telah menukar tetapan telefon untuk memasang aplikasi daripada sumber yang tidak diketahui, fail aplikasi MEGA888 ini akan dipasang tanpa sebarang halangan dalam telefon anda.",
  AndroidDownloadSteps8: "8. Kini, anda telah memasang aplikasi, bukanya dan mulakan kemajuan pendaftaran.",
};
