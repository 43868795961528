import httpClient, { ClientId } from "./HttpClient";
import { TopDataResponse } from "@/types/TopData/Response/TopDataResponse";

const getTopData = (type: number): Promise<TopDataResponse[]> => {
	const endPoint = "api/statement/topdata";
	const params = {
		type: type,
		clientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getTopData };
