<script>
import { defineComponent } from "vue";
import { Line } from "vue3-chart-v2";

export default defineComponent({
	name: "MonthlyChart",
	extends: Line,
	mounted() {
		// Overwriting base render method with actual data.
		this.renderChart({
			labels: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00"],
			title: "Withdrawal Chart",
			datasets: [
				{
					label: "Amount",
					borderWidth: 5,
					borderColor: "rgba(217, 27, 27, 1)",
					data: ["2000", "2400", "2400", "3600", "3200", "2000", "1200", "1400", "1280", "1800", "1600", "2200"],
				},
				{
					label: "Count",
					borderWidth: 5,
					borderColor: "rgba(248, 216, 64, 1)",
					data: ["30", "20", "36", "42", "48", "20", "36", "42", "32", "54", "20", "44"],
				},
			],
		});
	},
});
</script>
