// Header
import HeaderLogin from "@/resource/components/content/Header/HeaderLogin.zh";
import HeaderNavLink from "@/resource/components/content/Header/HeaderNavLink.zh";
import SideBar from "@/resource/components/content/Header/SideBar.zh";
import MobileSubHeader from "@/resource/components/content/Header/MobileSubHeader.zh";

//Home
import HomeDownload from "@/resource/components/content/Home/HomeDownload.zh";
import HomeFastPayment from "@/resource/components/content/Home/HomeFastPayment.zh";
import HomeHotSlot from "@/resource/components/content/Home/HomeHotSlot.zh";
import HomeJackpot from "@/resource/components/content/Home/HomeJackpot.zh";
import HomePromotion from "@/resource/components/content/Home/HomePromotion.zh";
import HomeWithdrawalChart from "@/resource/components/content/Home/HomeWithdrawalChart.zh";
import HomeReport from "@/resource/components/content/Home/HomeReport.zh";
import HomePlayerWon from "@/resource/components/content/Home/HomePlayerWon.zh";
import HomeSwitchSite from "@/resource/components/content/Home/HomeSwitchSite.zh";

//Mobile Footer
import MobileFooter from "@/resource/components/content/MobileFooter/MobileFooter.zh";

// Announcement
import RunningTextModal from "@/resource/components/modal/RunningTextModal.zh";
import EventView from "@/resource/components/content/Event/EventView.zh";

// Download
import DownloadAccount from "@/resource/components/content/Download/DownloadAccount.zh";
import DownloadQR from "@/resource/components/content/Download/DownloadQR.zh";
import DownloadPage from "@/resource/pages/download/DownloadPage.zh";

// Promotion
import PromotionItem from "@/resource/components/content/Promotion/PromotionItem.zh";
import PromotionPage from "@/resource/pages/promotion/PromotionPage.zh";

// VIP
import VipPage from "@/resource/pages/vip/VipPage.zh";

// Contact
import ContactPage from "@/resource/pages/contact/ContactPage.zh";

// Rank
import RankPage from "@/resource/pages/rank/RankPage.zh";

// Member
import ForgotPasswordPage from "@/resource/pages/member/ForgotPassword.zh";

//Statement
import StatementPage from "@/resource/pages/fund/StatementPage.zh";
import DepositItem from "@/resource/components/content/Fund/DepositItem.zh";
import WithdrawalItem from "@/resource/components/content/Fund/WithdrawalItem.zh";
import TransferItem from "@/resource/components/content/Fund/TransferItem.zh";
import BetHistoryItem from "@/resource/components/content/Fund/BetHistoryItem.zh";
import BonusItem from "@/resource/components/content/Fund/BonusItem.zh";
import AdjustmentItem from "@/resource/components/content/Fund/AdjustmentItem.zh";

//Referral
import ReferralPage from "@/resource/pages/fund/ReferralPage.zh";

// Footer
import TheFooter from "@/resource/components/layout/TheFooter.zh";
import AboutUsPage from "@/resource/pages/information/AboutUsPage.zh";
import TermsPage from "@/resource/pages/information/TermsPage.zh";
import PrivacyPolicyPage from "@/resource/pages/information/PrivacyPolicyPage.zh";
import ResponsibleGamingPage from "@/resource/pages/information/ResponsibleGamingPage.zh";

//Modal
import LoginModal from "@/resource/components/modal/LoginModal.zh";
import SignUpModal from "@/resource/components/modal/SignUpModal.zh";
import ForgotPasswordModal from "@/resource/components/modal/ForgotPasswordModal.zh";
import ChangePasswordModal from "@/resource/components/modal/ChangePasswordModal.zh";
import ProfileModal from "@/resource/components/modal/ProfileModal.zh";
import BankInfoModal from "@/resource/components/modal/BankInfoModal.zh";
import DepositModal from "@/resource/components/modal/DepositModal.zh";
import WithdrawalModal from "@/resource/components/modal/WithdrawalModal.zh";
import LangModal from "@/resource/components/modal/LangModal.zh";
import InterchangeModal from "@/resource/components/modal/InterchangeModal.zh";

//Signalr
import TheSignalr from "@/resource/components/layout/TheSignalr.zh";

//Floating Switch Site
import FloatingSwitchSite from "@/resource/components/content/FloatingSwitchSite/FloatingSwitchSite.zh";

export default {
  HeaderLogin,
  HeaderNavLink,
  SideBar,
  MobileSubHeader,
  HomeDownload,
  HomeFastPayment,
  HomeHotSlot,
  HomeJackpot,
  HomePromotion,
  HomeWithdrawalChart,
  HomeReport,
  HomePlayerWon,
  HomeSwitchSite,
  MobileFooter,
  RunningTextModal,
  EventView,
  DownloadAccount,
  DownloadQR,
  DownloadPage,
  PromotionItem,
  PromotionPage,
  ContactPage,
  RankPage,
  VipPage,
  ForgotPasswordPage,
  AboutUsPage,
  TermsPage,
  PrivacyPolicyPage,
  ResponsibleGamingPage,
  StatementPage,
  DepositItem,
  WithdrawalItem,
  TransferItem,
  ReferralPage,
  TheFooter,
  LoginModal,
  SignUpModal,
  ForgotPasswordModal,
  ChangePasswordModal,
  ProfileModal,
  BankInfoModal,
  DepositModal,
  WithdrawalModal,
  LangModal,
  InterchangeModal,
  BetHistoryItem,
  BonusItem,
  AdjustmentItem,
  TheSignalr,
  FloatingSwitchSite,
};
