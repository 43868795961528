export default {
  DOWNLOADNOW: "立即下载",
  Description1: "体验最好玩的在线赌场游戏",
  Description2: "扫描二维码",
  Title: "MEGA888的游戏",
  Description3: "您可以在MEGA888享受高品质的棋盘游戏。 此外,我们正在与主要供应商Real Time Gaming(RTG)合作。 这是全球赌场业的知名品牌。 因此,请放心,我们的游戏可以满足您所需的所有要求。 如果您认为自己可能会进入不公正的赌博系统,请不要担心。 毕竟,MEGA888仅与拥有100％合法内容的游戏开发人员合作。 请不要相信赌场对您不利或试图阻止您获胜。 我们的目标是满足我们的客户。",
  Description4: "在MEGA888上可以玩的在线娱乐场游戏数量众多,只要您每月在MEGA888上玩,对于任何玩家来说都很少会出现选择不足的情况。 MEGA888的娱乐内容广泛,从在线钓鱼游戏到在线老虎机游戏,再到各种现场桌游,例如IDN Poker,百家乐和轮盘赌。 无论您要搜索什么在线赌场游戏,MEGA888都有。",
  Description5: "2020年的新版本包括:",
  Game1: "Wolf Hunter",
  Game1Description: "Wolf Hunter是一款精美的老虎机游戏,具有3A级图形。 该游戏具有维多利亚时代的哥特式风格,并讲述了一个猎人夜间前往猎狼的故事。 这不仅是一些普通的狼,而且是狼人。 该游戏具有恐怖元素,可保持游戏的乐趣和刺激感。",
  Game2: "Motorcycle",
  Game2Description: "Motorcycle是老虎机游戏中刺激肾上腺素的体验,虚拟化了赛车的疯狂,这是该地区最流行,最流行的老虎机游戏之一。",
  Game3: "Dragon Maiden",
  Game3Description: "进入Dragon Maiden,您可以在其中选择驯服或杀死龙。 这是一款老虎机游戏,将其优先级放在其故事和讲故事元素上,使其成为老虎机游戏可以提供的更具吸引力和沉浸感的体验之一。",
  Game4: "Steam Tower",
  Game4Description: "Steam Tower是一款基于蒸汽朋克美学的老虎机游戏,讲述了一个孤独的侦探试图在困境中登上蒸汽塔以挽救少女的故事。 他在登顶途中必须面对各种顽强和危险,从怪物,龙到暴民。 他偏爱的武器是八叉鱼叉步枪。 如果您喜欢像《蒸汽朋克》这样的游戏,例如《生化奇兵》和《系统冲击》,那么这款游戏绝对适合您。",
  DownloadTitle : "如何下载MEGA888",
  iosDownload : "苹果下载",
  AndroidDownload : "安卓下载",
  iosDownloadDesription: "作为苹果用户,请注意细节,在苹果设备上安装第三方应用程序有些复杂,但是如果遵循这些步骤,则很容易完成。",
  AndroidDownloadDesription: "安卓下载更加简单明了； 但是,我们将向那些不用于更新第三方应用程序的人进行解释：",
  iosDownloadSteps1: "1. 打开您的浏览器,然后输入'http://www.mymega888.vip/Download'。 这将带您直接进入这个值得信赖的网站,您可以从该网站下载apk文件。",
  iosDownloadSteps2: "2. 对于您的苹果手机,选择应用程序的iOS版本。",
  iosDownloadSteps3: "3. 等待完成下载。 当弹出菜单显示安装时。",
  iosDownloadSteps4: "4. 当您的计算机上显示'下载成功'通知时,请按apk文件图标' MEGA888',然后等待显示'不受信任的企业开发人员'的弹出窗口,此错误消息应弹出,因为该网站不是本机。 苹果不允许下载不受信任的来源。",
  iosDownloadSteps5: "5. 下一步,单击iOS计算机上的'取消'按钮,离开浏览器标签,然后打开'设置'菜单。 选择'常规'下的'系统管理'选项,然后检查'所有美国大陆贸易有限公司'。 信任所有美国大陆贸易有限公司',然后按'突出显示'。 Bhd。'和'信任'确认。",
  iosDownloadSteps6: "6. 所有这些的原因是苹果设备具有内置的防火墙,可防止不受信任的网站访问和使用文件和应用程序。 MEGA888程序将以这种方式绕过防火墙,但要更改名称。 安装该程序后,您可以免费使用该应用程序。",
  AndroidDownloadSteps1: "1. 打开浏览器并输入'http://www.mymega888.vip/Download'。",
  AndroidDownloadSteps2: "2. 选择安卓选项。 将会提示您允许下载apk文件,批准它,最后等待它下载。",
  AndroidDownloadSteps3: "3. 下载apk后,从下载选择中选择它进行安装。",
  AndroidDownloadSteps4: "4. 您需要在'设置'中允许使用此选项来安装此第三方设备。",
  AndroidDownloadSteps5: "5. 点击手机上的'设置'按钮,然后滚动到更高版本的android机型中的'隐私'部分,这也是'隐藏的来源'。 无论显示哪种方式,都是您要编辑的选择。",
  AndroidDownloadSteps6: "6. 点击'允许'此选项可从非Google Play商店的应用程序源进行安装。",
  AndroidDownloadSteps7: "7. 点击'安装'。由于您已更改手机设置以安装来自未知来源的应用,因此此MEGA888 apk文件将在手机上顺利安装。",
  AndroidDownloadSteps8: "8. 现在您已经安装了应用程序,请打开它并开始注册。",
};
