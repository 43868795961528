<template>
	<b-container class="mt-5">
		<b-row>
      		<b-col cols="12" class="homeTitle">{{ $t("HomeWithdrawalChart.CHART") }}</b-col>
    	</b-row>
    	<br/>
		<WithdrawalChart />
	</b-container>
</template>

<script>
import { defineComponent } from "vue";
import WithdrawalChart from "./WithdrawalChart.vue";

export default defineComponent({
	name: "HomeWithdrawChart",
	components: {
		WithdrawalChart,
	},
});
</script>
